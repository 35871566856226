export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const CREATE_PROJECT_FULFILLED = 'CREATE_PROJECT_FULFILLED';
export const FETCH_PROJECTS_REJECTED = 'FETCH_PROJECTS_REJECTED';
export const QUERIES_LOADING = 'QUERIES_LOADING';
export const QUERIES_LOADING_FAILED = 'QUERIES_LOADING_FAILED';
export const QUERIES_LOADED = 'QUERIES_LOADED';
export const QUERY_CREATED = 'QUERY_CREATED';
export const QUERY_UPDATED = 'QUERY_UPDATED';
export const QUERY_DELETED = 'QUERY_DELETED';
export const DASHBOARDS_LOADING = 'DASHBOARDS_LOADING';
export const DASHBOARDS_LOADING_FAILED = 'DASHBOARDS_LOADING_FAILED';
export const DASHBOARDS_LOADED = 'DASHBOARDS_LOADED';
export const DASHBOARD_UNITS_LOADING = 'DASHBOARD_UNITS_LOADING';
export const DASHBOARD_UNITS_LOADED = 'DASHBOARD_UNITS_LOADED';
export const DASHBOARD_UNITS_LOADING_FAILED = 'DASHBOARD_UNITS_LOADING_FAILED';
export const ACTIVE_DASHBOARD_CHANGE = 'ACTIVE_DASHBOARD_CHANGE';
export const ACTIVE_PRE_DASHBOARD_CHANGE = 'ACTIVE_PRE_DASHBOARD_CHANGE';
export const DASHBOARD_CREATED = 'DASHBOARD_CREATED';
export const DASHBOARD_DELETED = 'DASHBOARD_DELETED';
export const UNITS_ORDER_CHANGED = 'UNITS_ORDER_CHANGED';
export const DASHBOARD_UNMOUNTED = 'DASHBOARD_UNMOUNTED';
export const WIDGET_DELETED = 'WIDGET_DELETED';
export const DASHBOARD_UPDATED = 'DASHBOARD_UPDATED';
export const SHOW_ANALYTICS_RESULT = 'SHOW_ANALYTICS_RESULT';
export const INITIALIZE_MTA_STATE = 'INITIALIZE_MTA_STATE';
export const INITIALIZE_CAMPAIGN_STATE = 'INITIALIZE_CAMPAIGN_STATE';
export const DASHBOARD_LAST_REFRESHED = 'DASHBOARD_LAST_REFRESHED';
export const QUERIES_LOADING_STOPPED = 'QUERIES_LOADING_STOPPED';
export const INITIALIZE_TOUCHPOINT_DIMENSIONS =
  'INITIALIZE_TOUCHPOINT_DIMENSIONS';
export const INITIALIZE_CONTENT_GROUPS = 'INITIALIZE_CONTENT_GROUPS';
export const SSO_LOGIN_FULFILLED = 'SSO_LOGIN_FULFILLED';
export const TEMPLATES_LOADED = 'TEMPLATES_LOADED';
export const TEMPLATES_LOADING = 'TEMPLATES_LOADING';
export const TEMPLATES_LOADING_FAILED = 'TEMPLATES_LOADING_FAILED';
export const ACTIVE_TEMPLATE_CHANGE = 'ACTIVE_TEMPLATE_CHANGE';
export const EVENT_DISPLAY_NAMES_LOADING = 'EVENT_DISPLAY_NAMES_LOADING';
export const EVENT_DISPLAY_NAMES_ERROR = 'EVENT_DISPLAY_NAMES_ERROR';
export const EVENT_DISPLAY_NAMES_LOADED = 'EVENT_DISPLAY_NAMES_LOADED';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';
export const USER_LOGOUT = 'USER_LOGOUT';

export const SET_ALERT_TEMPLATES = 'SET_ALERT_TEMPLATES';

export const NEW_DASHBOARD_TEMPLATES_MODAL_OPEN =
  'NEW_DASHBOARD_TEMPLATES_MODAL_OPEN';
export const NEW_DASHBOARD_TEMPLATES_MODAL_CLOSE =
  'NEW_DASHBOARD_TEMPLATES_MODAL_CLOSE';
export const ADD_DASHBOARD_MODAL_OPEN = 'ADD_DASHBOARD_MODAL_OPEN';
export const ADD_DASHBOARD_MODAL_CLOSE = 'ADD_DASHBOARD_MODAL_CLOSE';
export const UPDATE_PICKED_FIRST_DASHBOARD_TEMPLATE =
  'UPDATE_PICKED_FIRST_DASHBOARD_TEMPLATE';

export const CLOSE_GOLBAL_SEARCH = 'CLOSE_GOLBAL_SEARCH';
export const SHOW_GOLBAL_SEARCH = 'SHOW_GOLBAL_SEARCH';

export const UPDATE_ALL_ROUTES = 'UPDATE_ALL_ROUTES';

export const TEST_TOGGLE_SDK_VERIFICATION = 'TEST_TOGGLE_SDK_VERIFICATION';
