import { Dispatch } from 'redux';
import logger from 'Utils/logger';
import { changePlanType, getFeatureConfigData } from './services';
import { FeatureConfigActionType, FeatureConfigApiResponse } from './types';

export const fetchFeatureConfig =
  (projectId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FeatureConfigActionType.SET_FEATURE_CONFIG_LOADING });

      const response = (await getFeatureConfigData(
        projectId
      )) as FeatureConfigApiResponse;
      if (response?.data) {
        const meteringInfo = {};
        if (response?.data?.metering_info) {
          response?.data?.metering_info.forEach((info) => {
            meteringInfo[info.name] = info;
          });
        }
        dispatch({
          type: FeatureConfigActionType.UPDATE_FEATURE_CONFIG,
          payload: {
            activeFeatures: response?.data?.plan?.feature_list,
            addOns: response?.data?.add_ons,
            billingAddons: response?.data?.billing_addons,
            lastRenewedOn: response?.data?.last_renewed_on,
            plan: {
              id: response?.data?.plan?.id,
              name: response?.data?.plan?.name,
              display_name: response?.data?.display_name
            },
            meteringInfo
          }
        });
      }
    } catch (error) {
      logger.error('Error in fetching feature config', error);
      dispatch({ type: FeatureConfigActionType.SET_FEATURE_CONFIG_ERROR });
    }
  };

export const updatePlan =
  (projectId: string, planName: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: FeatureConfigActionType.SET_FEATURE_CONFIG_LOADING });

      const response = (await changePlanType(
        projectId,
        planName
      )) as FeatureConfigApiResponse;
      return response;
    } catch (error) {
      logger.error('Error in fetching feature config', error);
      dispatch({ type: FeatureConfigActionType.SET_FEATURE_CONFIG_ERROR });
    }
  };
