export const SET_ACCOUNT_PAYLOAD = 'SET_ACCOUNT_PAYLOAD';
export const UPDATE_ACCOUNT_PAYLOAD = 'UPDATE_ACCOUNT_PAYLOAD';
export const SET_ACCOUNT_SEGMENT_MODAL = 'SET_ACCOUNT_SEGMENT_MODAL';
export const ENABLE_NEW_SEGMENT_MODE = 'ENABLE_NEW_SEGMENT_MODE';
export const DISABLE_NEW_SEGMENT_MODE = 'DISABLE_NEW_SEGMENT_MODE';
export const SET_FILTERS_DIRTY = 'SET_FILTERS_DIRTY';
export const TOGGLE_ACCOUNTS_TAB = 'TOGGLE_ACCOUNTS_TAB';
export const ACCOUNTS_INSIGHTS_CONFIG_LOADING =
  'ACCOUNTS_INSIGHTS_CONFIG_LOADING';
export const ACCOUNTS_INSIGHTS_CONFIG_SUCCESS =
  'ACCOUNTS_INSIGHTS_CONFIG_SUCCESS';
export const ACCOUNTS_INSIGHTS_CONFIG_ERROR = 'ACCOUNTS_INSIGHTS_CONFIG_ERROR';

export const ACCOUNTS_INSIGHTS_LOADING = 'ACCOUNTS_INSIGHTS_LOADING';
export const ACCOUNTS_INSIGHTS_SUCCESS = 'ACCOUNTS_INSIGHTS_SUCCESS';
export const ACCOUNTS_INSIGHTS_ERROR = 'ACCOUNTS_INSIGHTS_ERROR';
export const SET_INSIGHTS_DURATION = 'SET_INSIGHTS_DURATION';
export const SET_INSIGHTS_COMPARE_SEGMENT = 'SET_INSIGHTS_COMPARE_SEGMENT';
export const EDIT_INSIGHTS_METRIC_LOADING = 'EDIT_INSIGHTS_METRIC_LOADING';
export const EDIT_INSIGHTS_METRIC_SUCCESS = 'EDIT_INSIGHTS_METRIC_SUCCESS';
export const EDIT_INSIGHTS_METRIC_ERROR = 'EDIT_INSIGHTS_METRIC_ERROR';
export const RESET_EDIT_INSIGHTS_METRIC = 'RESET_EDIT_INSIGHTS_METRIC';
