module.exports = {
  brand: {
    1: '#E6F7FF',
    2: '#BAE7FF',
    3: '#91D5FF',
    4: '#69C0FF',
    5: '#40A9FF',
    6: '#1890FF',
    7: '#096DD9',
    8: '#0050B3',
    9: '#003A8C',
    10: '#002766'
  },
  purple: {
    1: '#F9F0FF',
    2: '#EFDBFF',
    3: '#D3ADF7',
    4: '#B37FEB',
    5: '#9254DE',
    6: '#722ED1',
    7: '#531DAB',
    8: '#391085',
    9: '#22075E',
    10: '#120338'
  },
  magenta: {
    1: '#FFF0F6',
    2: '#FFD6E7',
    3: '#FFADD2',
    4: '#FF85C0',
    5: '#F759AB',
    6: '#EB2F96',
    7: '#C41D7F',
    8: '#9E1068',
    9: '#780650',
    10: '#520339'
  },
  geekblue: {
    1: '#F0F5FF',
    2: '#D6E4FF',
    3: '#ADC6FF',
    4: '#85A5FF',
    5: '#597EF7',
    6: '#2F54EB',
    7: '#1D39C4',
    8: '#10239E',
    9: '#061178',
    10: '#030852'
  },
  blue: {
    1: '#E6F7FF',
    2: '#BAE7FF',
    3: '#91D5FF',
    4: '#69C0FF',
    5: '#40A9FF',
    6: '#1890FF',
    7: '#096DD9',
    8: '#0050B3',
    9: '#003A8C',
    10: '#002766'
  },
  cyan: {
    1: '#E6FFFB',
    2: '#B5F5EC',
    3: '#87E8DE',
    4: '#5CDBD3',
    5: '#36CFC9',
    6: '#13C2C2',
    7: '#08979C',
    8: '#006D75',
    9: '#00474F',
    10: '#002329'
  },
  green: {
    1: '#F6FFED',
    2: '#D9F7BE',
    3: '#B7EB8F',
    4: '#95DE64',
    5: '#73D13D',
    6: '#52C41A',
    7: '#389E0D',
    8: '#237804',
    9: '#135200',
    10: '#092B00'
  },
  lime: {
    1: '#FCFFE6',
    2: '#F4FFB8',
    3: '#EAFF8F',
    4: '#D3F261',
    5: '#BAE637',
    6: '#A0D911',
    7: '#7CB305',
    8: '#5B8C00',
    9: '#3F6600',
    10: '#254000'
  },
  yellow: {
    1: '#FEFFE6',
    2: '#FFFFB8',
    3: '#FFFB8F',
    4: '#FFF566',
    5: '#FFEC3D',
    6: '#FADB14',
    7: '#D4B106',
    8: '#AD8B00',
    9: '#876800',
    10: '#614700'
  },
  gold: {
    1: '#FFFBE6',
    2: '#FFF1B8',
    3: '#FFE58F',
    4: '#FFD666',
    5: '#FFC53D',
    6: '#FAAD14',
    7: '#D48806',
    8: '#AD6800',
    9: '#874D00',
    10: '#613400'
  },
  orange: {
    1: '#FFF7E6',
    2: '#FFE7BA',
    3: '#FFD591',
    4: '#FFC069',
    5: '#FFA940',
    6: '#FA8C16',
    7: '#D46B08',
    8: '#AD4E00',
    9: '#873800',
    10: '#612500'
  },
  red: {
    1: '#FFF1F0',
    2: '#FFCCC7',
    3: '#FFA39E',
    4: '#FF7875',
    5: '#FF4D4F',
    6: '#F5222D',
    7: '#CF1322',
    8: '#A8071A',
    9: '#820014',
    10: '#5C0011'
  },
  volcano: {
    1: '#FFF2E8',
    2: '#FFD8BF',
    3: '#FFBB96',
    4: '#FF9C6E',
    5: '#FF7A45',
    6: '#FA541C',
    7: '#D4380D',
    8: '#AD2102',
    9: '#871400',
    10: '#610B00'
  },
  gray: {
    1: '#FFFFFF',
    2: '#FAFAFA',
    3: '#F5F5F5',
    4: '#F0F0F0',
    5: '#D9D9D9',
    6: '#BFBFBF',
    7: '#8C8C8C',
    8: '#595959',
    9: '#434343',
    10: '#262626',
    11: '#1F1F1F',
    12: '#141414',
    13: '#000000',
    200: '#edf2f7',
    300: '#e2e8f0',
    600: '#718096',
    700: '#4a5568',
    900: '#1a202c'
  },
  charcoal: {
    2: '#00000005',
    4: '#0000000A',
    6: '#0000000F',
    15: '#00000026',
    25: '#00000040',
    45: '#00000073',
    65: '#000000A6',
    85: '#000000D8'
  },
  chart: {
    1: '#85A5FF',
    2: '#69C0FF',
    3: '#5CDBD3',
    4: '#95DE64',
    5: '#FFD666',
    6: '#FFC069',
    7: '#FF9C6E',
    8: '#FF7875',
    9: '#FF85C0',
    10: '#B37FEB'
  },
  black: '#000000',
  white: '#FFFFFF'
};