import { FEATURES } from 'Constants/plans.constants';
import { FeatureConfig } from './types';

export const getAllActiveFeatures = (
  planFeatures?: FeatureConfig[] | null,
  addons?: FeatureConfig[]
) => {
  let activeFeatures: FeatureConfig[] = [];
  if (Array.isArray(planFeatures)) {
    activeFeatures = [...planFeatures];
  }
  if (Array.isArray(addons)) {
    activeFeatures = [...activeFeatures, ...addons];
  }

  return activeFeatures;
};

export const getFeatureStatusInfo = (
  featureName: (typeof FEATURES)[keyof typeof FEATURES],
  planFeatures?: FeatureConfig[],
  addons?: FeatureConfig[],
  billingAddons?: FeatureConfig[]
): { isFeatureLocked: boolean } => {
  const activeFeatures = getAllActiveFeatures(planFeatures, addons);
  // if feature is enabled from chargebee addons
  if (
    billingAddons &&
    Array.isArray(billingAddons) &&
    billingAddons?.find((feature) => feature.name === featureName)
  ) {
    return {
      isFeatureLocked: false
    };
  }
  const feature = activeFeatures.find(
    (feature) => feature.name === featureName
  );
  if (!feature) {
    return {
      isFeatureLocked: true
    };
  }
  return {
    isFeatureLocked: !feature?.is_enabled_feature
  };
};

export const allowOperationIfLimitIsExceeded = (email: string) => {
  // returning cases where the operation can still be allowed even if limit is exceeded
  // const flag = AdminLock(email);
  const flag = false;
  return flag;
};
