export const SET_DRAFTS_SELECTED = 'SET_DRAFTS_SELECTED';
export const INITIATED_DASHBOARD_NEW_FOLDER_CREATION =
  'INITIATED_DASHBOARD_NEW_FOLDER_CREATION';
export const DASHBOARD_NEW_FOLDER_CREATION_SUCCESSFUL =
  'DASHBOARD_NEW_FOLDER_CREATION_SUCCESSFUL';
export const DASHBOARD_NEW_FOLDER_CREATION_FAILED =
  'DASHBOARD_NEW_FOLDER_CREATION_FAILED';
export const TOGGLE_DASHBOARD_NEW_FOLDER_MODAL =
  'TOGGLE_DASHBOARD_NEW_FOLDER_MODAL';
export const DASHBOARD_FOLDERS_LIST_LOADING = 'DASHBOARD_FOLDERS_LIST_LOADING';
export const DASHBOARD_FOLDERS_LIST_SUCCESS = 'DASHBOARD_FOLDERS_LIST_SUCCESS';
export const DASHBOARD_FOLDERS_LIST_ERROR = 'DASHBOARD_FOLDERS_LIST_ERROR';
export const INITIATED_DASHBOARD_MOVE_TO_EXISTING_FOLDER =
  'INITIATED_DASHBOARD_MOVE_TO_EXISTING_FOLDER';
export const DASHBOARD_MOVE_TO_EXISTING_FOLDER_SUCCESSFUL =
  'DASHBOARD_MOVE_TO_EXISTING_FOLDER_SUCCESSFUL';
export const DASHBOARD_MOVE_TO_EXISTING_FOLDER_FAILED =
  'DASHBOARD_MOVE_TO_EXISTING_FOLDER_FAILED';
export const INITIATED_RENAME_DASHBOARD_FOLDER =
  'INITIATED_RENAME_DASHBOARD_FOLDER';
export const RENAME_DASHBOARD_FOLDER_SUCCESSFUL =
  'RENAME_DASHBOARD_FOLDER_SUCCESSFUL';
export const RENAME_DASHBOARD_FOLDER_FAILED = 'RENAME_DASHBOARD_FOLDER_FAILED';
export const INITIATED_DELETE_DASHBOARD_FOLDER =
  'INITIATED_DELETE_DASHBOARD_FOLDER';
export const DELETE_DASHBOARD_FOLDER_SUCCESSFUL =
  'DELETE_DASHBOARD_FOLDER_SUCCESSFUL';
export const DELETE_DASHBOARD_FOLDER_FAILED = 'DELETE_DASHBOARD_FOLDER_FAILED';
export const INITIATE_DASHBOARD_DELETION = 'INITIATE_DASHBOARD_DELETION';
export const INITIATE_EDIT_DASHBOARD_DETAILS =
  'INITIATE_EDIT_DASHBOARD_DETAILS';
