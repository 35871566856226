export const SEGMENT_DELETED = 'SEGMENT_DELETED';
export const SET_ACCOUNT_SEGMENT_FOLDERS = 'SET_ACCOUNT_SEGMENT_FOLDERS';
export const SET_PEOPLE_SEGMENT_FOLDERS = 'SET_PEOPLE_SEGMENT_FOLDERS';
export const SET_ACCOUNTS_SEGMENT_FOLDERS_FAILED =
  'SET_ACCOUNTS_SEGMENT_FOLDERS_FAILED';
export const SET_PEOPLES_SEGMENT_FOLDERS_FAILED =
  'SET_PEOPLES_SEGMENT_FOLDERS_FAILED';

export const FETCH_PROFILE_ACCOUNTS_LOADING = 'FETCH_PROFILE_ACCOUNTS_LOADING';
export const FETCH_PROFILE_ACCOUNTS_LOADED = 'FETCH_PROFILE_ACCOUNTS_LOADED';
export const SET_SEGMENT_TEMPLATES = 'SET_SEGMENT_TEMPLATES';
