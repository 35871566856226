export const PathUrls = {
  Dashboard: '/reports',
  DashboardURL: '/reports/:dashboard_id',
  PreBuildDashboard: '/reports/quick-board',
  PreBuildDashboardReport: '/report/quick-board',
  ComponentsLib: '/components',
  Analyse: '/analyse/:query_type/:query_id',
  Analyse1: '/analyse/:query_type',
  Analyse2: '/analyse',
  Explain: '/explain',

  ExplainInsightsV2: '/explainV2/insights',
  ProfilePeople: '/profiles/people',
  ProfilePeopleDetailsURL: '/profiles/people/:id',
  ProfileAccounts: '/',
  ProfileAccountsSegmentsURL: '/accounts/segments/:segment_id',
  ProfileAccountDetailsURL: '/profiles/accounts/:id',
  VisitorIdentificationReport: '/reports/visitor_report',
  PathAnalysis: '/path-analysis',
  PathAnalysisInsights: '/path-analysis/insights',

  FreqCap: '/adpilot/freq-cap',
  FreqCapView: '/adpilot/freq-cap/:rule_id',
  AudienceSync: '/adpilot/audience-sync',
  AudienceSyncView: '/adpilot/audience-sync/:rule_id',
  LiConversionAPI: '/adpilot/li-conversion-api',
  LiConversionAPIView: '/adpilot/li-conversion-api/:rule_id',

  // general settings
  SettingsGeneral: '/settings/general',
  SettingsMembers: '/settings/members',
  SettingsPricing: '/settings/pricing',
  SettingsSharing: '/settings/project/sharing',
  SettingsLoginAndSecurity: '/settings/login_security',
  ProjectChangeAuthentication: '/project_change_authentication',
  TwoFactorsAuthentication: '/2fa/authentication',

  // personal settings
  SettingsPersonalUser: '/settings/user',
  SettingsPersonalProjects: '/settings/projects',

  // data management settings
  SettingsIntegration: '/settings/integration',
  SettingsIntegrationURLID: '/settings/integration/:integration_id',
  IntegrationCallbackRedirection: '/callback/integration/:integration_id',
  SettingsTouchpointDefinition: '/settings/touchpoint_definition',
  SettingsCustomDefinition: '/settings/custom_definition',
  SettingsAttribution: '/settings/attribution',
  SettingsAccountScoring: '/settings/account_scoring',
  SettingsEngagementScoringEditRule:
    '/settings/account_scoring?activeTab=engagementScoring&mode=edit',
  SettingsEngagementScoringCreateRule:
    '/settings/account_scoring?activeTab=engagementScoring&mode=create',
  SettingsInterestGroupEditRule:
    '/settings/account_scoring?activeTab=interestGroup&mode=edit',
  SettingsInterestGroupCreateRule:
    '/settings/account_scoring?activeTab=interestGroup&mode=create',
  SettingsIntentUploadCreateRule:
    '/settings/account_scoring?activeTab=intentUpload&mode=create',
  ConfigurePlans: '/settings/plans',
  ConfigurePlansAdmin: '/settings/plans/admin',

  Settings: '/settings',
  Upgrade: '/upgrade',
  Onboarding: '/onboarding',
  Checklist: '/checklist',

  // automations
  Alerts: '/automations/alerts',
  RealTimeAlerts: '/automations/alerts/realtime',
  RealTimeAlertID: '/automations/alerts/realtime/:alert_id',
  WeeklyAlerts: '/automations/alerts/weekly',
  WeeklyAlertID: '/automations/alerts/weekly/:alert_id',

  Workflows: '/automations/workflows',
  ActivateSSO: '/activate/sso'
};
