import { defaultState } from './constants';

const {
  SHOW_GOLBAL_SEARCH,
  CLOSE_GOLBAL_SEARCH,
  SET_ACTIVE_PROJECT
} = require('Reducers/types');

export default function (state = defaultState, action) {
  switch (action.type) {
    case SHOW_GOLBAL_SEARCH:
      return { ...state, visible: true };
    case CLOSE_GOLBAL_SEARCH:
      return { ...state, visible: false };
    case SET_ACTIVE_PROJECT: {
      return {
        ...defaultState
      };
    }
    default:
      return state;
  }
}
