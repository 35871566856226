export const SET_TIMELINE_PAYLOAD = 'SET_TIMELINE_PAYLOAD';
export const SET_PROFILES_ACTIVE_SEGMENT = 'SET_PROFILES_ACTIVE_SEGMENT';
export const UPDATE_TIMELINE_PAYLOAD = 'UPDATE_TIMELINE_PAYLOAD';
export const SET_PROFILE_SEGMENT_MODAL = 'SET_PROFILE_SEGMENT_MODAL';
export const SET_PROFILE_FILTERS_DIRTY = 'SET_PROFILE_FILTERS_DIRTY';
export const ENABLE_PROFILE_NEW_SEGMENT_MODE =
  'ENABLE_PROFILE_NEW_SEGMENT_MODE';
export const DISABLE_PROFILE_NEW_SEGMENT_MODE =
  'DISABLE_PROFILE_NEW_SEGMENT_MODE';
export const PROFILE_SEGMENT_DELETED = 'PROFILE_SEGMENT_DELETED';
